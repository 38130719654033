import React, { useEffect, useState } from 'react';
import { useFilter } from 'components/context/FilterContext';
import { useQuery } from '@apollo/client';
import GET_CATEGORIES from './graphql/GET_CATEGORIES';
import { GET_BLOG_POSTS } from './graphql/GET_BLOG_POSTS';
import Pagination from './Pagination';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

type ArticleListingComponentProps = {
	fields: any;
	sitecoreContext: any;
};

const ArticleListingComponent = (props: ArticleListingComponentProps): JSX.Element => {
	const { setFilterState } = useFilter();
	const [index, setIndex] = useState(0);
	const pageSize = 12;

	const { sitecoreContext } = props;
	if (!sitecoreContext.searchState) {
		sitecoreContext.searchState = {
			searchText: '',
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			triggerSearch: () => {},
		};
	}

	const [prevSearchText, setPrevSearchText] = useState(sitecoreContext?.searchState?.searchText);

	const { data: queryData } = useQuery(GET_CATEGORIES, {
		variables: {
			path: '{2F3908C9-8A4D-444D-80B2-FC30C6E216D1}',
			templateID: '{38CF2D0D-C5FE-4BFD-84CD-E4EED14D0F94}',
		},
		context: { clientName: 'searchOnEdge' },
	});

	const { data, refetch } = useQuery(GET_BLOG_POSTS, {
		variables: {
			text: sitecoreContext.searchState.searchText,
			page: 0,
			pageSize,
			filters: {
				categories: [],
				tags: [],
				authors: [],
			},
		},
	});

	useEffect(() => {
		if (queryData && queryData.search) {
			setFilterState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
					categoriesList: queryData.search.results || [],
				},
			}));
		}
	}, [queryData]);

	useEffect(() => {
		refetch({
			text: sitecoreContext.searchState?.searchText,
			page: sitecoreContext.searchState?.searchText !== prevSearchText ? 0 : index + 1,
			pageSize,
			filters: {
				categories: [],
				tags: [],
				authors: [],
			},
		});

		setPrevSearchText(sitecoreContext.searchState?.searchText);
	}, [index, sitecoreContext.searchState?.searchText]);

	useEffect(() => {
		sitecoreContext.searchState.triggerSearch = handleSearch;
	}, []);

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-GB', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
	};

	const handleSearch = (e?: React.FormEvent) => {
		if (e) e.preventDefault();
		setIndex(0);
		refetch({
			text: sitecoreContext.searchState.searchText,
			page: 0,
			pageSize,
			filters: {
				categories: [],
				tags: [],
				authors: [],
			},
		});
	};

	return (
		<div>
			<div className="blog-intro-wrapper">
				<div className="blog-intro overhauled">
					<h1 className="osf-display-h1">{sitecoreContext.route.fields?.Title?.value}</h1>
					<p className="blog-description">
						<RichText field={sitecoreContext.route.fields?.Description} />
					</p>
				</div>
			</div>
			<div className="article-listing overhauled">
				<div className="articles-grid">
					{data?.blogSearch?.results?.map((article: any) => (
						<a
							href={article.blogLink.replace(/^.*?(?=\/Blog\/Posts)/, '')}
							key={article.blogLink}
							className="article-card"
						>
							<div className="article-image">
								<img src={article.blogImage} alt={article.title} />
							</div>
							<div className="article-content">
								<div className="article-date">{formatDate(article.publishedDate)}</div>
								<h2 className="summary-title osf-inner-h3">{article.title}</h2>
							</div>
						</a>
					))}
				</div>
			</div>
			<div className="pagination-container">
				{data?.blogSearch?.totalSearchResults > pageSize && (
					<Pagination
						numberOfVisiblePages={5}
						itemsPerPage={pageSize}
						numberOfItems={data.blogSearch.totalSearchResults}
						index={index}
						setIndex={setIndex}
					/>
				)}
			</div>
		</div>
	);
};

export default withSitecoreContext()(ArticleListingComponent);
