import React, { useState, FormEvent } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';

type PLDFormFields = {
	submitterName: string;
	submitterEmail: string;
	submitterRole: string;
	providerName: string;
	providerNPI: string;
	providerLocation: string;
	providerPhone: string;
	gender: string;
	addSpecialty: string;
	removeSpecialty: string;
	addLanguages: string;
	removeLanguages: string;
	addCertification: string;
	removeCertification: string;
	addTraining: string;
	removeTraining: string;
	incorrectLocation: string;
	missingLocation: string;
	removeLocation: string;
	locationRank: string;
	locationPhone: string;
	locationOther: string;
	addFocusArea: string;
	removeFocusArea: string;
	focusApproval: string;
	accepting: string;
	biography: string;
	photo: File | '';
	comments: string;
};

const initialState: PLDFormFields = {
	submitterName: '',
	submitterEmail: '',
	submitterRole: '',
	providerName: '',
	providerNPI: '',
	providerLocation: '',
	providerPhone: '',
	gender: '',
	addSpecialty: '',
	removeSpecialty: '',
	addLanguages: '',
	removeLanguages: '',
	addCertification: '',
	removeCertification: '',
	addTraining: '',
	removeTraining: '',
	incorrectLocation: '',
	missingLocation: '',
	removeLocation: '',
	locationRank: '',
	locationPhone: '',
	locationOther: '',
	addFocusArea: '',
	removeFocusArea: '',
	focusApproval: '',
	accepting: '',
	biography: '',
	photo: '',
	comments: '',
};

const failedMsg = 'Sorry, something went wrong with the submission. Please try again.';

const PLDForm = () => {
	const [formFields, setFormFields] = useState({ ...initialState });
	const [validEmail, setValidEmail] = useState<boolean | null>(null);
	const [validPhone, setValidPhone] = useState<boolean | null>(null);
	const [validImage, setValidImage] = useState<boolean | null>(null);
	const [responseMsg, setResponseMsg] = useState('');

	const handleChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLSelectElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;

		if (value.length == 0) {
			e.target.closest('.field-wrapper')?.querySelector('.error-msg')?.classList.remove('show');
		}
		setFormFields((prevFields) => ({
			...prevFields,
			[name]: value,
		}));
	};

	const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;

		setFormFields((prevFields) => ({
			...prevFields,
			[name]: checked ? 'true' : 'false',
		}));
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileUpload = e.target.files?.[0];
		const img = new Image();
		img.src = (fileUpload && URL.createObjectURL(fileUpload)) || '';
		img.onload = function () {
			if (!(img.width >= 1200) || !(img.height >= 1200)) {
				setValidImage(false);
			} else {
				setValidImage(true);
				setResponseMsg('');
			}
			fileUpload &&
				setFormFields((prevFields) => ({
					...prevFields,
					photo: fileUpload,
				}));
		};
	};

	const clearImg = (input: HTMLInputElement) => {
		input.value = '';
		setValidImage(null);
		setFormFields((prevFields) => ({
			...prevFields,
			photo: '', // Store empty string instead of { value: '' }
		}));
		setResponseMsg('');
	};

	const validateEmail = () => {
		if (formFields.submitterEmail.endsWith('@osfhealthcare.org')) {
			setValidEmail(true);
			setResponseMsg('');
		} else {
			setValidEmail(false);
		}
	};

	const toggleField = (e: MouseEvent) => {
		e.preventDefault();
		const toggleBtn = e.target as HTMLElement;
		toggleBtn.closest('.expandable-field')?.classList.toggle('show');
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e?.preventDefault();
		if (validEmail && validImage !== false) {
			const formValues = Object.fromEntries(
				Object.entries(formFields).filter(([_, value]) => value !== '' && value !== null)
			);

			setResponseMsg('Thank you. Working on submitting...Please wait for confirmation.');
			axios
				.post(
					`https://prod-24.northcentralus.logic.azure.com:443/workflows/b287770306504f3db37a52dfd71699b8/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=hzyKF-mxOQhF75LpvPdoS75Rc1yc3C-ZnlxoGeV0TY4`,
					{
						formFields: formValues,
					},
					{
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json',
							//SAVE FOR FUTURE AUTH ON LOGIC APP 'x-api-key': 'hzyKF-mxOQhF75LpvPdoS75Rc1yc3C-ZnlxoGeV0TY4'
							//Authorization: `Basic ${Constants.api.epicAccessToken}`,
						},
					}
				)
				.then(function () {
					window.location.href = '/provider-update/success';
				})
				.catch(function () {
					setResponseMsg(failedMsg);
				});
		} else if (!validEmail) {
			setResponseMsg('Please verify your email address.');
		} else {
			setResponseMsg('Please update or remove the added photo.');
		}
	};

	return (
		<div className="overhauled">
			<div className="jss-inner-container pld-form outer-vertical-spacing">
				<h1 className="title">Provider Update Request Form</h1>
				<p className="osf-intro-copy">
					Provider data from various sources is displayed on osfhealthcare.org in the provider
					directory. Use this form to request corrections to a provider's directory profile.
				</p>
				<div className="form-wrapper">
					<form onSubmit={handleSubmit} id="pld-form">
						<h3>Submitter Details</h3>
						<fieldset>
							<div className="field-wrapper">
								<label htmlFor="submitterName">
									Submitter Name<span className="required">*</span>
								</label>
								<input
									id="submitterName"
									type="text"
									placeholder="Ex: John Doe"
									name="submitterName"
									value={formFields.submitterName}
									onChange={handleChange}
									required
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="submitterEmail">
									Submitter Email<span className="required">*</span>
									<em> - Please use your OSF email.</em>
								</label>
								<input
									id="submitterEmail"
									type="text"
									placeholder="Ex: john.doe@osfhealthcare.org"
									name="submitterEmail"
									value={formFields.submitterEmail}
									onBlur={validateEmail}
									onChange={handleChange}
									required
								></input>
								<em
									className={
										validEmail == false ? 'error-msg show osf-label' : 'error-msg osf-label'
									}
								>
									Please use your OSF email.
								</em>
							</div>
							<div className="field-wrapper">
								<label htmlFor="submitterRole">
									Submitter Role<span className="required">*</span>
								</label>
								<input
									id="submitterRole"
									type="text"
									name="submitterRole"
									value={formFields.submitterRole}
									onChange={handleChange}
									required
								></input>
							</div>
						</fieldset>
						<h3>Provider Information</h3>
						<fieldset>
							<div className="field-wrapper">
								<label htmlFor="providerName">
									Provider Name<span className="required">*</span>
								</label>
								<input
									id="providerName"
									type="text"
									placeholder="Ex: John Doe"
									name="providerName"
									value={formFields.providerName}
									onChange={handleChange}
									required
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="providerNPI">
									Provider NPI<span className="required">*</span>
								</label>
								<input
									id="providerNPI"
									type="text"
									name="providerNPI"
									value={formFields.providerNPI}
									onChange={handleChange}
									required
								></input>
							</div>
							<div className="field-wrapper">
								<label>
									Provider Location<span className="required">*</span>
								</label>
								<div>
									<input
										id="ilProvider"
										type="radio"
										name="providerLocation"
										value="IL Provider"
										onChange={handleChange}
										required
									></input>
									<label htmlFor="ilProvider" className="radio-label">
										IL Provider
									</label>
									<input
										id="miProvider"
										type="radio"
										name="providerLocation"
										value="MI Provider"
										onChange={handleChange}
										required
									></input>
									<label htmlFor="miProvider" className="radio-label">
										MI Provider
									</label>
								</div>
							</div>
						</fieldset>
						<h3>Personal Information</h3>
						<p className="instructions">
							Name changes can only take place if the medical license is up to date and must follow
							our standard{' '}
							<a href="https://osfprod.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=19c51805dbcef410339f6f13ca961996&searchTerm=name%20change">
								HR name change process
							</a>
							. Any updates in this section must match the provider's medical license.
						</p>
						<fieldset>
							<div className="field-wrapper">
								<label htmlFor="providerPhone">Phone Number</label>
								<InputMask
									id="providerPhone"
									mask="999-999-9999"
									maskChar={null}
									value={formFields.providerPhone}
									onChange={handleChange}
									placeholder="Ex: ###-###-####"
									name="providerPhone"
									type="text"
									onBlur={(e) => setValidPhone(e.target.value.length == 12)}
								/>
								<em
									className={
										validPhone == false ? 'error-msg show osf-label' : 'error-msg osf-label'
									}
								>
									Please provide a valid phone number in the provided format.
								</em>
							</div>
							<div className="field-wrapper">
								<label>Gender</label>
								<div>
									<input
										id="male"
										type="radio"
										name="gender"
										value="Male"
										onChange={handleChange}
									></input>
									<label htmlFor="male" className="radio-label">
										Male
									</label>
									<input
										id="female"
										type="radio"
										name="gender"
										value="Female"
										onChange={handleChange}
									></input>
									<label htmlFor="female" className="radio-label">
										Female
									</label>
								</div>
							</div>
							<div className="field-wrapper">
								<label htmlFor="addSpecialty">Add Specialty</label>
								<input
									id="addSpecialty"
									type="text"
									placeholder="Ex: Family Medicine"
									name="addSpecialty"
									value={formFields.addSpecialty}
									onChange={handleChange}
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="removeSpecialty">Remove Specialty</label>
								<input
									id="removeSpecialty"
									type="text"
									placeholder="Ex: Family Medicine"
									name="removeSpecialty"
									value={formFields.removeSpecialty}
									onChange={handleChange}
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="addLanguages">Add Languages</label>
								<input
									id="addLanguages"
									type="text"
									placeholder="Ex: English"
									name="addLanguages"
									value={formFields.addLanguages}
									onChange={handleChange}
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="removeLanguages">Remove Languages</label>
								<input
									id="removeLanguages"
									type="text"
									placeholder="Ex: English"
									name="removeLanguages"
									value={formFields.removeLanguages}
									onChange={handleChange}
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="addCertification">Add Board Certification</label>
								<input
									id="addCertification"
									type="text"
									placeholder="Ex: Family Nurse Practitioner - American Academy of Nurse Practitioners, 2015"
									name="addCertification"
									value={formFields.addCertification}
									onChange={handleChange}
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="removeCertification">Remove Board Certification</label>
								<input
									id="removeCertification"
									type="text"
									placeholder="Ex: Family Nurse Practitioner - American Academy of Nurse Practitioners, 2015"
									name="removeCertification"
									value={formFields.removeCertification}
									onChange={handleChange}
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="addTraining">Add Training/Education</label>
								<input
									id="addTraining"
									type="text"
									placeholder="Ex: OSF Saint Francis Medical Center - Fellowship, 2015"
									name="addTraining"
									value={formFields.addTraining}
									onChange={handleChange}
								></input>
							</div>
							<div className="field-wrapper">
								<label htmlFor="removeTraining">Remove Training/Education</label>
								<input
									id="removeTraining"
									type="text"
									placeholder="Ex: OSF Saint Francis Medical Center - Fellowship, 2015"
									name="removeTraining"
									value={formFields.removeTraining}
									onChange={handleChange}
								></input>
							</div>
						</fieldset>
						<h3>Location Information</h3>
						<p className="instructions">
							Use this form to report incorrect or missing location details. If the location is an
							Epic department, provide the Epic Department ID in your details.
						</p>
						<div className="location-wrapper">
							<div className="expandable-field">
								<label htmlFor="incorrectLocation">
									<button
										className="btn-secondary"
										onClick={(e: any) => {
											toggleField(e);
										}}
									>
										Incorrect Location
									</button>
								</label>
								<div className="field-wrapper">
									<p className="osf-label">
										Enter the incorrect location, the correct location, and the correct order
									</p>
									<textarea
										id="incorrectLocation"
										name="incorrectLocation"
										onChange={handleChange}
										value={formFields.incorrectLocation}
									/>
								</div>
							</div>
							<div className="expandable-field">
								<label htmlFor="missingLocation">
									<button
										className="btn-secondary"
										onClick={(e: any) => {
											toggleField(e);
										}}
									>
										Missing Location
									</button>
								</label>
								<div className="field-wrapper">
									<p className="osf-label">
										Enter the location that should be linked and the correct order
									</p>
									<textarea
										id="missingLocation"
										name="missingLocation"
										onChange={handleChange}
										value={formFields.missingLocation}
									></textarea>
								</div>
							</div>
							<div className="expandable-field">
								<label htmlFor="removeLocation">
									<button
										className="btn-secondary"
										onClick={(e: any) => {
											toggleField(e);
										}}
									>
										Removal of a Location
									</button>
								</label>
								<div className="field-wrapper">
									<p className="osf-label">Enter the location that needs to be removed</p>
									<textarea
										id="removeLocation"
										name="removeLocation"
										onChange={handleChange}
										value={formFields.removeLocation}
									/>
								</div>
							</div>
							<div className="expandable-field">
								<label htmlFor="locationRank">
									<button
										className="btn-secondary"
										onClick={(e: any) => {
											toggleField(e);
										}}
									>
										Incorrect Location Rank
									</button>
								</label>
								<div className="field-wrapper">
									<p className="osf-label">Enter the locations and their correct order</p>
									<textarea
										id="locationRank"
										name="locationRank"
										onChange={handleChange}
										value={formFields.locationRank}
									/>
								</div>
							</div>
							<div className="expandable-field">
								<label htmlFor="locationPhone">
									<button
										className="btn-secondary"
										onClick={(e: any) => {
											toggleField(e);
										}}
									>
										Phone Number
									</button>
								</label>
								<div className="field-wrapper">
									<p className="osf-label">
										Provide the incorrect phone number and the correct phone number
									</p>
									<textarea
										id="locationPhone"
										name="locationPhone"
										onChange={handleChange}
										value={formFields.locationPhone}
									/>
								</div>
							</div>
							<div className="expandable-field">
								<label htmlFor="locationOther">
									<button
										className="btn-secondary"
										onClick={(e: any) => {
											toggleField(e);
										}}
									>
										Other
									</button>
								</label>
								<div className="field-wrapper">
									<p className="osf-label">
										Enter any other comments or details related to your request
									</p>
									<textarea
										id="locationOther"
										name="locationOther"
										onChange={handleChange}
										value={formFields.locationOther}
									/>
								</div>
							</div>
						</div>
						<h3>Marketable Information</h3>
						<p className="instructions">
							For any field you want to update, enter the new information that should be in the
							profile.
						</p>
						<div className="expandable-field">
							<label>
								<button
									className="btn-secondary"
									onClick={(e: any) => {
										toggleField(e);
									}}
								>
									Clinical Focus Area
								</button>
							</label>
							<div className="field-wrapper">
								<label htmlFor="addFocusArea">Add Clinical Focus Area</label>
								<textarea
									id="addFocusArea"
									name="addFocusArea"
									value={formFields.addFocusArea}
									onChange={handleChange}
									placeholder="Ex: Internal Medicine"
								/>
								<label htmlFor="removeFocusArea">Remove Clinical Focus Area</label>
								<textarea
									id="removeFocusArea"
									name="removeFocusArea"
									value={formFields.removeFocusArea}
									onChange={handleChange}
									placeholder="Ex: Internal Medicine"
								/>
								<input
									type="checkbox"
									id="focusApproval"
									name="focusApproval"
									onChange={handleCheck}
									checked={formFields.focusApproval == 'true'}
									required={
										formFields.addFocusArea.length > 0 ||
										formFields.removeFocusArea.length > 0
									}
								></input>
								<label htmlFor="focusApproval">
									Clinical focus is clinically appropriate for provider or has been reviewed and
									approved by managing medical director.
									{(formFields.addFocusArea.length > 0 ||
										formFields.removeFocusArea.length > 0) && (
										<span className="required">*</span>
									)}
								</label>
							</div>
						</div>

						<div className="field-wrapper">
							<label>
								Accepting New Patients -{' '}
								<em>
									Note, the accepting new patients flag is not department specific and if a provider
									is accepting new patients at at-least one location, this should be set to Yes.
								</em>
							</label>
							<div>
								<input
									id="isAccepting"
									type="radio"
									name="accepting"
									value="Yes"
									onChange={handleChange}
								></input>
								<label htmlFor="isAccepting" className="radio-label">
									Yes
								</label>
								<input
									id="notAccepting"
									type="radio"
									name="accepting"
									value="No"
									onChange={handleChange}
								></input>
								<label htmlFor="notAccepting" className="radio-label">
									No
								</label>
							</div>
						</div>
						<div className="field-wrapper">
							<label htmlFor="biography">Biography</label>
							<textarea
								id="biography"
								name="biography"
								value={formFields.biography}
								onChange={handleChange}
								placeholder="Ex: I treat all patients with kindness and respect. It is important to me to provide personal education as well, so they feel informed and comfortable making decisions about their health care."
							></textarea>
						</div>
						<div className="field-wrapper">
							<label htmlFor="photo">
								Photo Attachment - <em>Photo must be at least 1200x1200 pixels</em>
							</label>
							<input
								id="photo"
								type="file"
								name="photo"
								onChange={handleImageChange}
								accept="image/*"
							></input>
							<button
								type="button"
								className={`${validImage !== null ? 'shown' : ''}  remove-btn btn-tertiary`}
								onClick={() => clearImg(document.getElementById('photo') as HTMLInputElement)}
							>
								Remove
							</button>
							<em
								className={validImage == false ? 'error-msg show osf-label' : 'error-msg osf-label'}
							>
								Photo must be at least 1200x1200 pixels.
							</em>
						</div>
						<div className="btns-wrapper">
							<button type="submit" className="btn-primary submit-btn">
								Submit
							</button>
						</div>
					</form>
					<div>
						<h2 className="response-msg">{responseMsg}</h2>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PLDForm;
