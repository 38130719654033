import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import { classNames } from 'assets/utils/helper';
import ImageGrid from 'components/atoms/ImageGrid';
import { getImaginarysRecaptchaKey, getEcardUrl } from 'lib/util';
//import { getRecaptchaKey, getGoogleAssessmentKey } from 'lib/util';

type eCardFormFields = {
	facility: string;
	title: string;
	from: string;
	patient: string;
	message: string;
	image: string;
};

const initialState: eCardFormFields = {
	facility: '',
	title: '',
	from: '',
	patient: '',
	message: '',
	image: '',
};

type CardTitle = [string, string];

type Facility = [number, string];

type ImageObject = {
	id: number;
	tag: string;
	url: string;
	thumb_url: string;
};

type FormOptions = {
	card_titles: Array<CardTitle>;
	facilities: Array<Facility>;
	thumbnails: Array<ImageObject>;
};

declare global {
	interface Window {
		grecaptcha: any;
	}
}

const eCard = (): JSX.Element => {
	const [formFields, setFormFields] = useState({ ...initialState });
	const [formOptions, setFormOptions] = useState({} as FormOptions);
	const [selected, setSelected] = useState(Number);
	const [errorMsg, setErrorMsg] = useState('');
	const [submitMsg, setSubmitMsg] = useState('Send an eCard');
	const imaginarysKey = getImaginarysRecaptchaKey();
	const eCardUrl = getEcardUrl();
	const maxMsgLength = 300;
	const router = useRouter();
	// Imaginary's Recaptcha URL
	const recaptchaUrl = 'https://www.google.com/recaptcha/api.js';
	//const acceptedReason = 'LOW_CONFIDENCE_SCORE';

	const loadRecaptcha = () => {
		const siteHead = document.getElementsByTagName('head')[0];
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = recaptchaUrl;
		siteHead.appendChild(script);
	};

	const selectImage = (id: number, name: string) => {
		setSelected(id);
		setFormFields((prevFields) => ({
			...prevFields,
			[name]: id,
		}));
		setErrorMsg('');
	};

	const handleChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLSelectElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setFormFields((prevFields) => ({
			...prevFields,
			[name]: value,
		}));
		value !== '' && setErrorMsg('');
	};

	const validateForm = () => {
		let emptyFields = Array<string>();

		const nonEmptyKeys = Object.keys(formFields).filter((keyName) => {
			return keyName !== 'title';
		});
		nonEmptyKeys.forEach((keyName) => {
			if (formFields[keyName as keyof eCardFormFields] == '') {
				emptyFields.push(keyName);
			}
		});
		if (emptyFields.length > 0) {
			if (emptyFields.length == 1 && emptyFields[0] == 'image') {
				setErrorMsg('Please select an image.');
			} else {
				setErrorMsg('Pleease ensure all fields are populated.');
			}
			return false;
		} else {
			return true;
		}
	};

	const handleSubmit = async (e: Event) => {
		e.preventDefault();

		if (validateForm()) {
			//const recaptchaKey = getRecaptchaKey();
			//const assessmentKey = getGoogleAssessmentKey();
			// window.grecaptcha.enterprise.ready(async () => {
			// 	const token = await window.grecaptcha.enterprise.execute(recaptchaKey, {
			// 		action: 'form_submit',
			// 	});

			//assesment call - get response
			// const assessmentData = await axios.post(
			// 	`https://recaptchaenterprise.googleapis.com/v1/projects/magnetic-tesla-344917/assessments?key=${assessmentKey}`,
			// 	{
			// 		event: {
			// 			token: token,
			// 			expectedAction: 'form_submit',
			// 			siteKey: recaptchaKey,
			// 		},
			// 	}
			// );

			// const reasons = assessmentData.data.riskAnalysis.reasons;

			// if (!reasons || reasons[0] == acceptedReason) {
			const captchaToken = window.grecaptcha.getResponse();

			if (captchaToken) {
				setSubmitMsg('Submitting...');
				const csrfToken = (await axios.get('api/get-csrf-token')).data.csrfToken;
				if (csrfToken) {
					try {
						const response = await axios.post(
							'/api/submit-ecard',
							{
								captcha_token: captchaToken,
								website_id: `${formFields.facility}`,
								image: `${formFields.image}`,
								title: `${formFields.title}`,
								sender_name: `${formFields.from}`,
								patient_name: `${formFields.patient}`,
								message: `${formFields.message}`,
							},
							{
								headers: {
									'Content-Type': 'application/json',
									'X-CSRFToken': `${csrfToken}`,
									Accept: 'application/json',
								},
							}
						);
						(response.status == 200 || 201) && router.push('/ecards/success');
					} catch (error) {
						setErrorMsg('There was an error with the request. Please try again.');
					}
				} else {
					setErrorMsg('Unable to authenticate user. Please try again.');
				}
			} else {
				setErrorMsg('Please perform recaptcha challenge.');
			}
		}
	};

	useEffect(() => {
		const getData = async () => {
			const response = await axios.get('/api/form-content');
			const result = await response.data;
			setFormOptions(result);
		};
		getData();

		const facilityInput = document.getElementById('facility');
		facilityInput?.addEventListener('focus', loadRecaptcha, { once: true });
	}, []);

	return (
		<div className="overhauled">
			<div className="jss-inner-container outer-vertical-spacing ecard-form-wrapper">
				<h1 className="form-title">Send an eCard</h1>
				<p className="description">
					Please use the form below to send a friendly message to an OSF HealthCare patient.
				</p>
				<form id="ecard-form">
					<div className="columns-wrapper outer-vertical-spacing">
						<div className="column">
							{/* FACILITY */}
							<div className={'field-wrapper'}>
								<label htmlFor="facility">
									Select the facility: <span className="required">*</span>
								</label>
								<select
									className=""
									id="facility"
									name="facility"
									value={formFields.facility}
									onChange={handleChange}
									required
								>
									{formOptions?.facilities?.map((facility, index) => {
										return (
											<option key={index} value={facility[0]}>
												{facility[1]}
											</option>
										);
									})}
								</select>
							</div>
							{/* TITLE */}
							<div className={'field-wrapper'}>
								<label htmlFor="title">
									Card Title: <span className="required">*</span>
								</label>
								<div className="form-text-field form-text-selector">
									<select
										className="selector"
										id="title"
										name="title"
										value={formFields.title}
										onChange={handleChange}
									>
										{formOptions?.card_titles?.map((title, index) => {
											return (
												<option key={index} value={title[0]}>
													{title[1]}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							{/* FROM */}
							<div className={'field-wrapper'}>
								<label htmlFor="from">
									From: <span className="required">*</span>
								</label>
								<input
									id="from"
									type="text"
									placeholder="Required Field"
									name="from"
									value={formFields.from}
									onChange={handleChange}
									required
								></input>
							</div>
							{/* PATIENT */}
							<div className={'field-wrapper'}>
								<label htmlFor="patient">
									Patient: <span className="required">*</span>
								</label>
								<div className="form-text-field form-text-input">
									<input
										id="patient"
										type="text"
										placeholder="Required Field"
										name="patient"
										value={formFields.patient}
										onChange={handleChange}
										required
									></input>
								</div>
							</div>
							{/* MESSAGE */}
							<div className={'field-wrapper'}>
								<label htmlFor="message">
									Message <span className="required">*</span> ({maxMsgLength} character max)
								</label>
								<textarea
									id="message"
									name="message"
									placeholder="Enter your message here"
									maxLength={maxMsgLength}
									value={formFields.message}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="image-selection-container mobile">
								<div className="form-input-wrapper">
									<label className="form-label" htmlFor="image-radio">
										Select an image:<span className="required">*</span>
									</label>
									<div className="image-selector">
										<div className="image-selections">
											{formOptions.thumbnails?.map((thumbnail: ImageObject, index: number) => (
												<div
													className={classNames(
														thumbnail.id === selected ? 'img-selector active' : 'img-selector'
													)}
													key={index}
												>
													<input
														type="radio"
														value={thumbnail.id}
														name="image"
														checked={formFields.image == thumbnail.id.toString()}
														required
														onChange={() => selectImage(thumbnail.id, 'image')}
													/>
													<ImageGrid {...thumbnail} />
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="live-preview mobile">
								<label className="form-label">Preview:</label>
								<div className="green-banner">
									<div className="two-col">
										<div className="left-col">
											{formOptions.thumbnails && formFields.image ? (
												<img
													src={`${eCardUrl}${
														formOptions.thumbnails.find(
															(img) => img.id.toString() == formFields.image
														)?.url
													}`}
												/>
											) : (
												<div className="img"></div>
											)}
										</div>
										<div className="right-col">
											{formFields.title && (
												<h3 className="osf-inner-h3 title">{formFields.title}</h3>
											)}
											{formFields.patient && (
												<p className="patient-name">TO: {formFields.patient}</p>
											)}
											{formFields.from && <p className="sender-name">FROM: {formFields.from}</p>}
											{formFields.message && <div className="message">{formFields.message}</div>}
										</div>
									</div>
								</div>
							</div>
							<button className="btn-primary" onClick={(e) => handleSubmit(e as unknown as Event)}>
								{submitMsg}
							</button>
							{errorMsg && <p className="error-msg">{errorMsg}</p>}
							<p>
								<span className="required">*</span>Indicates required fields.
							</p>
							<div className="g-recaptcha" data-sitekey={imaginarysKey}></div>
						</div>
						<div className="column">
							<div className="image-selection-container desktop">
								<div className="form-input-wrapper">
									<label className="form-label" htmlFor="images" id="img-label">
										Select an image:<span className="required">*</span>
									</label>
									<div className="image-selector">
										<div className="image-selections">
											{formOptions.thumbnails?.map((thumbnail: ImageObject, index: number) => (
												<div
													className={classNames(
														thumbnail.id === selected ? 'img-selector active' : 'img-selector'
													)}
													key={index}
												>
													<input
														aria-label="img"
														type="radio"
														value={thumbnail.id}
														name="image"
														id={`image${thumbnail.id}`}
														checked={formFields.image == thumbnail.id.toString()}
														required
														onChange={() => selectImage(thumbnail.id, 'image')}
														role="radio"
													/>
													<ImageGrid {...thumbnail} />
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							{/* LIVE PREVIEW */}
							<div className="live-preview desktop">
								<label className="form-label">Preview:</label>
								<div className="green-banner">
									<div className="two-col">
										<div className="left-col">
											{formOptions.thumbnails && formFields.image ? (
												<img
													src={`${eCardUrl}${
														formOptions.thumbnails.find(
															(img) => img.id.toString() == formFields.image
														)?.url
													}`}
												/>
											) : (
												<div className="img"></div>
											)}
										</div>
										<div className="right-col">
											{formFields.title && (
												<h3 className="osf-inner-h3 title">{formFields.title}</h3>
											)}
											{formFields.patient && (
												<p className="patient-name">TO: {formFields.patient}</p>
											)}
											{formFields.from && <p className="sender-name">FROM: {formFields.from}</p>}
											{formFields.message && <div className="message">{formFields.message}</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
				<div className="card-summary">
					<p>
						Please note that your message will be viewed by selected OSF staff members for
						processing and delivery.&nbsp;
					</p>

					<p>
						Messages will not be delivered if they are deemed inappropriate, violate the wishes of
						the patient or patient’s family or if the patient has been discharged.&nbsp;
					</p>

					<p>
						Messages are distributed on the first business day after the request is submitted.&nbsp;
					</p>

					<p>
						If you need to reach us for any reason, please use our{' '}
						<a href="https://x.osfhealthcare.org/patients-visitors/contact"> Contact Us </a> form.
					</p>

					<p>Information on this page is secure. We value your privacy.</p>
				</div>
			</div>
		</div>
	);
};

export default eCard;
