import { gql } from '@apollo/client';

export const GET_BLOG_POSTS = gql`
	query Search(
		$text: String!
		$page: Int!
		$pageSize: Int!
		$filters: BlogFiltersInput
		$sortType: SortType
	) {
		blogSearch(text: $text, page: $page, pageSize: $pageSize, sort: $sortType, filters: $filters) {
			totalSearchResults
			results {
				title
				blogImage
				blogLink
				summary
				modifiedDate
				publishedDate
				categories
				tags
				body
				authors
			}
		}
	}
`;
