import Head from 'next/head';

const reloadPage = () => {
	if (window) {
		window.location.reload();
	}
};
const NotFound = (): JSX.Element => (
	<>
		<Head>
			<script
				dangerouslySetInnerHTML={{
					__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-KHGKWQN');`,
				}}
			></script>
			<meta name="google-site-verification" content="r_LVnSC68L_eZX1ClIEwBuwt-YrN9MPhQqCqBCXzvUs" />
			<title>404: Not Found</title>
		</Head>
		<noscript>
			<iframe
				src="https://www.googletagmanager.com/ns.html?id=GTM-KHGKWQN"
				height="0"
				width="0"
				style={{ display: 'none', visibility: 'hidden' }}
			></iframe>
		</noscript>
		<div className="jss-container">
			<div className="error-page-container">
				<div className="error-page-inner">
					<div className="error-status">ERROR 404 - NOT FOUND</div>
					<div className="error-desc">
						<div className="error-desc">
							<div className="error-wrapper">
								<h1>Something's not quite right. Let's help find what you're looking for.</h1>

								<h4>You can try:</h4>

								<div>
									<a className="text-plus color-green" href="#" onClick={() => reloadPage()}>
										Refreshing the page
									</a>
								</div>
								<div>
									<a className="text-plus color-green" href="https://x.osfhealthcare.org">
										Return to the OSF HealthCare home page
									</a>
								</div>
								<div>
									<a
										className="text-plus color-green"
										href=" https://x.osfhealthcare.org/patients-visitors/website-feedback"
									>
										Report broken link
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
);

export default NotFound;
