import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

interface PillProps {
	type: string;
	pills: {
		id: string;
		value: string;
		text: string;
		href: string;
	}[];
}
const PillList: React.FC<PillProps> = ({ pills, type }) => {
	const router = useRouter();

	const getParentPath = () => {
		const segments = router.asPath.split('?')[0].split('/');
		segments.pop();
		return segments.join('/');
	};

	const handleClick = (e: React.MouseEvent, type: string, pillId: string) => {
		e.preventDefault();

		const parentPath = getParentPath();
		router.push(`${parentPath}?${type}=${pillId.replace(/-/g, '').toUpperCase()}`);
	};

	return (
		<div className="pill-list">
			{pills &&
				pills.map((pill) => (
					<Link
						key={pill.id}
						href={{
							pathname: getParentPath(),
							query: { ...router.query, type: `${pill.id.replace(/-/g, '').toUpperCase()}` },
						}}
						passHref
					>
						<a onClick={(e) => handleClick(e, type, pill.id)} className={`pill action-btn`}>
							<RichText field={{ value: pill.text[0].toUpperCase() + pill.text.slice(1) }} />
						</a>
					</Link>
				))}
		</div>
	);
};
export default PillList;
