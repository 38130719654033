import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { GetStaticPaths, GetStaticProps } from 'next';
import sitecoreRedirect from 'lib/sc-redirect';
import NotFound from 'src/NotFound';
import Layout from 'src/Layout';
import {
	SitecoreContext,
	ComponentPropsContext,
	handleExperienceEditorFastRefresh,
	RouteData,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideSitecoreContextValue } from 'lib/component-props';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { componentFactory } from 'temp/componentFactory';
import { sitemapFetcher } from 'lib/sitemap-fetcher';

const SitecorePage = ({ notFound, layoutData, componentProps }: SitecorePageProps): JSX.Element => {
	const router = useRouter();
	const isRedirecting = useRef(false);

	useEffect(() => {
		// Handle Experience Editor Fast Refresh
		handleExperienceEditorFastRefresh();

		// Handle client-side redirects
		const handleRedirect = async () => {
			if (isRedirecting.current) {
				return;
			}

			const resolvedPath = Array.isArray(router.query.path)
				? `/${router.query.path.join('/')}`
				: `/${router.query.path || ''}`;

			const redirect = await sitecoreRedirect(resolvedPath);

			if (redirect) {
				isRedirecting.current = true;
				router.push(redirect.destination);
				return;
			}
		};

		// Check for redirects even if the page is not found
		if (notFound || !layoutData?.sitecore?.route) {
			handleRedirect();
		} else {
			handleRedirect();
		}
	}, [router.query.path, notFound, layoutData]);

	if (notFound || (!layoutData?.sitecore?.route && !isRedirecting.current)) {
		return <NotFound />;
	}

	const context: StyleguideSitecoreContextValue = {
		route: layoutData?.sitecore.route || ({} as RouteData),
		itemId: layoutData?.sitecore.route?.itemId || undefined,
		...(layoutData?.sitecore.context || {}),
	};

	return (
		<ComponentPropsContext value={componentProps}>
			<SitecoreContext<StyleguideSitecoreContextValue>
				componentFactory={componentFactory}
				context={context}
			>
				<Layout context={context} />
			</SitecoreContext>
		</ComponentPropsContext>
	);
};

// This function gets called at build and export time to determine pages for SSG
export const getStaticPaths: GetStaticPaths = async (context) => {
	if (process.env.NODE_ENV !== 'development') {
		const paths = await sitemapFetcher.fetch(context);
		return {
			paths,
			fallback: true,
		};
	}

	return {
		paths: [],
		fallback: true,
	};
};

// This function gets called at build time (SSG) and on demand if ISR is enabled
export const getStaticProps: GetStaticProps<SitecorePageProps> = async (context) => {
	const defaultLocale = context.defaultLocale || 'en';

	try {
		const props = await sitecorePagePropsFactory.create(context);

		if (!props.layoutData?.sitecore?.route && context.params?.path) {
			let sourceUrl = '/';

			// Convert path array to a valid URL
			if (Array.isArray(context.params.path)) {
				sourceUrl += context.params.path.join('/');
			} else {
				sourceUrl += context.params.path;
			}

			const redirect = await sitecoreRedirect(sourceUrl);

			if (redirect) {
				return {
					redirect,
				};
			}

			return {
				notFound: true,
			};
		}

		return {
			props: props || { notFound: true, layoutData: null, componentProps: {} },
			revalidate: 5,
			notFound: props.notFound,
		};
	} catch (error) {
		return {
			props: {
				statusCode: 500,
				statusMessage: error instanceof Error ? error.message : 'Internal Server Error',
				layoutData: null,
				componentProps: {},
				locale: defaultLocale,
				dictionary: {},
				notFound: true,
				error: true,
			},
			revalidate: 5,
		};
	}
};

export default SitecorePage;
