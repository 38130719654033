import React from 'react';
import PillList from 'components/atoms/PillList';

type TagsItem = {
	id: string;
	displayName: string;
	fields: {
		hashvalue: {
			value: string;
		};
		link: {
			value: {
				href: string;
			};
		};
	};
};

type CategoriesItem = {
	id: string;
	displayName: string;
	fields: {
		hashvalue: {
			value: string;
		};
		link: {
			value: {
				href: string;
			};
		};
	};
};

type BlogCategoriesProps = {
	fields: {
		Tags: Array<TagsItem>;
		Categories: Array<CategoriesItem>;
	};
};

const BlogCategories = (props: BlogCategoriesProps): JSX.Element => {
	const { fields } = props;

	return (
		<div className="overhauled blog-pills content-wrapper">
			<h4>Explore more:</h4>
			<PillList
				type="category-tag"
				pills={[
					...fields.Categories?.map(({ id, displayName, fields: { link, hashvalue } }) => {
						return {
							id,
							value: hashvalue?.value || '',
							href: link.value?.href || '#',
							text: displayName || '',
						};
					}),
					...fields.Tags?.map(({ id, displayName, fields: { link, hashvalue } }) => {
						return {
							id,
							value: hashvalue?.value || '',
							href: link.value?.href || '#',
							text: displayName || '',
						};
					}),
				]}
			/>
		</div>
	);
};

export default BlogCategories;
