import React from 'react';
import Icons from './Icons';
import { classNames } from 'assets/utils/helper';
import Image from 'next/image';
import { getEcardUrl } from 'lib/util';

type ImageProps = {
	id: number;
	tag: string;
	url: string;
	thumb_url: string;
};

const ImageGrid = (props: ImageProps): JSX.Element => {
	const eCardUrl = getEcardUrl();
	return (
		<div key={props.id} className={classNames('image-container')}>
			{!props.url ? (
				<Icons id="image_grid" />
			) : (
				<Image src={`${eCardUrl}${props.url}`} alt={props.tag} width={'80px'} height={'80px'} />
			)}
		</div>
	);
};

export default ImageGrid;
