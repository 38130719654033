import React from 'react';

interface FeaturedBlogsProps {
	fields: {
		title: string;
		description: string;
		image: string;
	};
}

const FeaturedBlog: React.FC<FeaturedBlogsProps> = (props) => {
	// const { title, description, image } = fields;
	console.log(props);
	return (
		<div>
			{/* <h2>{title}</h2>
			<p>{description}</p>
			<img src={image} alt={title} /> */}
		</div>
	);
};

export default FeaturedBlog;
