/**
 * Get the publicUrl.
 * This is used primarily to enable compatibility with the Sitecore Experience Editor.
 */
export const getPublicUrl = (): string => {
	return process.env.PUBLIC_URL || '';
};

export const getRecaptchaKey = (): string => {
	return process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY || '';
};

export const getGoogleAssessmentKey = (): string => {
	return process.env.NEXT_PUBLIC_GOOGLE_ASSESSMENT_KEY || '';
};

export const getImaginarysRecaptchaKey = (): string => {
	return process.env.NEXT_PUBLIC_IMAGINARYS_RECAPTCHA || '';
};

export const getEcardUrl = (): string => {
	return process.env.NEXT_PUBLIC_ECARD_URL || '';
};
