import Icons from 'components/atoms/Icons';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useState, useEffect } from 'react';

const Search = (props: any): JSX.Element => {
	const { sitecoreContext } = props;
	if (!sitecoreContext.searchState) {
		sitecoreContext.searchState = {
			searchText: '',
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			triggerSearch: () => {},
		};
	}

	const [searchText, setSearchText] = useState(sitecoreContext.searchState?.searchText || '');

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (sitecoreContext.searchState?.triggerSearch) {
			sitecoreContext.searchState.triggerSearch(e);
		}
		const button = (e.target as HTMLFormElement).querySelector('button');
		if (button) {
			button.blur();
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setSearchText(newValue);
		if (sitecoreContext.searchState) {
			sitecoreContext.searchState.searchText = newValue;
		}
	};

	useEffect(() => {
		if (sitecoreContext.searchState) {
			setSearchText(sitecoreContext.searchState.searchText);
		}
	}, [sitecoreContext.searchState.searchText]);

	return (
		<div className="search-wrapper">
			<div className="search-bar overhauled">
				<form onSubmit={handleSubmit}>
					<div className="search-input">
						<div className="input-wrapper">
							<Icons id="search" className="search-icon" />
							<input
								type="text"
								placeholder="Keywords"
								value={searchText}
								onChange={handleChange}
							/>
						</div>
						<button type="submit" className="btn-primary">
							Search
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default withSitecoreContext()(Search);
