import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useRef } from 'react';
import { useRouter } from 'next/router';

interface AuthorProps {
	id: string;
	fields: {
		'Author Image': {
			value: {
				src: string;
				alt: string;
			};
		};
		'Author Name': {
			value: string;
		};
		Email: {
			value: string;
		};
		hashvalue: {
			value: string;
		};
		link: LinkField;
		Phone: {
			value: string;
		};
		slug: {
			value: string;
		};
		Summary: {
			value: string;
		};
		'WordPress Id': {
			value: string;
		};
	};
}

const AuthorComponent: React.FC<AuthorProps> = ({ fields, id }) => {
	const summaryRef = useRef<HTMLDivElement>(null);
	const router = useRouter();

	const getParentPath = () => {
		const segments = router.asPath.split('?')[0].split('/');
		segments.pop();
		return segments.join('/');
	};
	const handleViewAllPosts = (e: React.MouseEvent) => {
		e.preventDefault();
		const parentPath = getParentPath();
		router.push(`${parentPath}?author=${id.replace(/-/g, '').toUpperCase()}}`);
	};

	return (
		<div className="author-component">
			<div className="author-container">
				{fields && fields['Author Name'] ? (
					<h4>
						About the author {` `}
						<a href="" target="_blank" rel="noreferrer" className="" onClick={handleViewAllPosts}>
							{fields['Author Name']?.value}
						</a>
					</h4>
				) : (
					<h4>About the author</h4>
				)}
				<div className="author-info">
					<div ref={summaryRef} className={`summary expanded`}>
						<p
							className="content-paragraph"
							dangerouslySetInnerHTML={{ __html: fields ? fields?.Summary?.value : '' }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuthorComponent;
