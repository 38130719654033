import React from 'react';
import ChevronUp from '../../assets/images/icons/chevron-up.svg';
import ChevronDown from '../../assets/images/icons/chevron-down.svg';
import ChevronColoured from '../../assets/images/icons/chevron-down-colored.svg';
import FacebookSquare from '../../assets/images/icons/facebook.svg';
import InstagramSquare from '../../assets/images/icons/instagram.svg';
import YoutubeSquare from '../../assets/images/icons/youtube.svg';
import LinkedInSquare from '../../assets/images/icons/linkedin.svg';
import TwitterSquare from '../../assets/images/icons/twitter.svg';
import Critical from '../../assets/images/icons/critical.svg';
import Info from '../../assets/images/icons/info-circle.svg';
import Close from '../../assets/images/icons/close.svg';
import Warning from '../../assets/images/icons/warning.svg';
import FacebookRound from '../../assets/images/icons/facebook.svg';
import LinkedInRound from '../../assets/images/icons/linkedin.svg';
import TwitterRound from '../../assets/images/icons/twitter.svg';
import Search from '../../assets/images/icons/search.svg';
import SearchGreen from '../../assets/images/icons/search-green.svg';
import Location from '../../assets/images/icons/location.svg';
import LocationHome from '../../assets/images/icons/location-home.svg';
import DefaultProfile from '../../assets/images/icons/profile-md.svg';
import Map from '../../assets/images/icons/map.svg';
import MapMarker from '../../assets/images/icons/map-marker.svg';
import Menu from '../../assets/images/icons/menu.svg';
import ArrowGo from '../../assets/images/icons/arrow-go.svg';
import ArrowGoDefault from '../../assets/images/icons/arrow-go-md.svg';
import ArrowLeft from '../../assets/images/icons/arrow-left.svg';
import Profile from '../../assets/images/icons/profile.svg';
import ImageGrid from '../../assets/images/icons/image_grid.svg';
import ChevronLeftIcon from '../../assets/images/icons/chevron-left.svg';
import ChevronRightIcon from '../../assets/images/icons/chevron-right.svg';
import Share from '../../assets/images/icons/share.svg';
import Print from '../../assets/images/icons/print.svg';
import Calender from '../../assets/images/icons/calendar.svg';
import Expand from '../../assets/images/icons/expand.svg';
import ExpandFS from '../../assets/images/icons/expand-fs.svg';
import PlayFilled from '../../assets/images/icons/play-filled.svg';
import ChevronIcon from '../../assets/images/icons/chevron-next.svg';
import MapParking from '../../assets/images/icons/map-parking.svg';
import CellPhone from '../../assets/images/icons/cellphone.svg';
import Nurse from '../../assets/images/icons/nurse.svg';
import CalendarTwo from '../../assets/images/icons/calendar-two.svg';
import MenuThree from '../../assets/images/icons/menu-three.svg';
import CloseHeader from '../../assets/images/icons/close-header.svg';
import PlayBtn from '../../assets/images/icons/play-btn.svg';
import PauseBtn from '../../assets/images/icons/pause-btn.svg';
import Circle from '../../assets/images/icons/circle.svg';
import Square from '../../assets/images/icons/square.svg';
import Triangle from '../../assets/images/icons/triangle.svg';
import MyChart from '../../assets/images/icons/i-mychart-icon.svg';
import LocationIcon from '../../assets/images/icons/i-location-icon.svg';
import ICellPhoneIconRBG from '../../assets/images/icons/i-cellphone-icon-rgb.svg';
import ConCall from '../../assets/images/icons/con-call.svg';
import ConApply from '../../assets/images/icons/con-apply.svg';
import ConSchedule from '../../assets/images/icons/con-schedule.svg';
import GetCareNowIcon from '../../assets/images/icons/get-care-now-icon.svg';
import LocationPinned from '../../assets/images/icons/location-pinned-icon.svg';

type IconsProps = {
	id: string;
	className?: string;
	isDefault?: boolean;
	isColored?: boolean;
	removeSize?: boolean;
};

const Icons: React.FC<IconsProps> = ({ id, isDefault, isColored, removeSize, ...props }) => {
	switch (id) {
		case 'chevron-up':
			return <ChevronUp {...props} />;
		case 'icon-share':
			return <Share {...props} />;
		case 'icon-print':
			return <Print {...props} />;
		case 'chevron-down':
			return <ChevronDown {...props} />;
		case 'DownIcon':
			return <ChevronColoured {...props} />;
		case 'icon-critical':
		case 'critical':
			return <Critical {...props} />;
		case 'info-circle':
		case 'icon-info-circle':
		case 'default':
			return <Info {...props} />;
		case 'close':
			return <Close {...props} />;
		case 'menu':
			return <Menu {...props} />;
		case 'image_grid':
			return <ImageGrid {...props} />;
		case 'arrow-left':
			return <ArrowLeft {...props} />;
		case 'arrow-go':
		case 'arrow-up':
		case 'icon-arrow-go':
			return isDefault ? <ArrowGoDefault {...props} /> : <ArrowGo {...props} />;
		case 'icon-profile':
			return isDefault ? <DefaultProfile {...props} /> : <Profile {...props} />;
		case 'facebook-square':
			return <FacebookSquare {...props} />;
		case 'instagram':
		case 'instagram-square':
			return <InstagramSquare {...props} />;
		case 'youtube':
		case 'youtube-square':
			return <YoutubeSquare {...props} />;
		case 'linkedin-square':
			return <LinkedInSquare {...props} />;
		case 'twitter-square':
			return <TwitterSquare {...props} />;
		case 'warning':
		case 'icon-warning':
			return <Warning {...props} />;
		case 'facebook-round':
			return <FacebookRound {...props} />;
		case 'linkedin-round':
			return <LinkedInRound {...props} />;
		case 'twitter-round':
			return <TwitterRound {...props} />;
		case 'search':
		case '#search':
		case 'icon-search':
		case 'ìcon-search':
			return isColored ? <SearchGreen {...props} /> : <Search {...props} />;
		case 'location':
		case 'icon-location':
			return <Location {...props} />;
		case 'chevron-left-icon':
			return <ChevronLeftIcon {...props} />;
		case 'chevron-right-icon':
			return <ChevronRightIcon {...props} />;
		case 'icon-calendar':
			return <Calender {...props} />;
		case 'calendar-two':
			return <CalendarTwo {...props} />;
		case 'icon-expand':
			return <Expand {...props} />;
		case 'icon-expand-fs':
			return <ExpandFS {...props} />;
		case 'icon-play-filled':
			return <PlayFilled {...props} />;
		case 'chevron-next':
			return <ChevronIcon {...props} />;
		case 'map-parking':
			return <MapParking {...props} />;
		case 'cellphone':
			return <CellPhone {...props} />;
		case 'nurse':
			return <Nurse {...props} />;
		case 'menu-three':
			return <MenuThree {...props} />;
		case 'close-header':
			return <CloseHeader {...props} />;
		case 'play-btn':
			return <PlayBtn {...props} />;
		case 'pause-btn':
			return <PauseBtn {...props} />;
		case 'map':
			return <Map {...props} />;
		case 'map-marker':
			return <MapMarker {...props} />;
		case 'location-home':
			return <LocationHome {...props} />;
		case 'circle':
			return <Circle {...props} />;
		case 'square':
			return <Square {...props} />;
		case 'triangle':
			return <Triangle {...props} />;
		case 'mychart':
			return <MyChart {...props} />;
		case 'location-icon':
			return <LocationIcon {...props} />;
		case 'i-cellphone-icon-rgb':
			return <ICellPhoneIconRBG {...props} />;
		case 'con-call':
			return <ConCall {...props} />;
		case 'con-apply':
			return <ConApply {...props} />;
		case 'con-schedule':
			return <ConSchedule {...props} />;
		case 'get-care-now-icon':
			return <GetCareNowIcon {...props} />;
		case 'location-pinned':
			return <LocationPinned {...props} />;
		default:
			return <></>;
	}
};

export default Icons;
