import React, { useMemo } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import AuthorComponent from 'components/r2/BlogAuthor';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useQuery } from '@apollo/client';
import { GET_BLOG_POSTS } from 'components/r2/graphql/GET_BLOG_POSTS';
import Icons from 'components/atoms/Icons';
import BlogCategories from 'components/r2/BlogCategories';

interface Author {
	id: string;
	url: string;
	name: string;
	displayName: string;
	fields: {
		'Author Image': {
			value: {
				src: string;
				alt: string;
			};
		};
		'Author Name': {
			value: string;
		};
		Email: {
			value: string;
		};
		hashvalue: {
			value: string;
		};
		link: LinkField;
		Phone: {
			value: string;
		};
		slug: {
			value: string;
		};
		Summary: {
			value: string;
		};
		'WordPress Id': {
			value: string;
		};
	};
}

interface RawHtmlProps {
	html: string;
}
interface TextField {
	value: string;
}

interface LinkField {
	value: {
		href: string;
	};
}

interface ImageField {
	value: {
		src?: string;
		alt?: string;
		width?: number;
		height?: number;
		srcset?: string;
		sizes?: string;
	};
}

interface Category {
	id: string;
	url: string;
	name: string;
	displayName: string;
	fields: {
		hashvalue: TextField;
		link: LinkField;
		Name: TextField;
		WordPressId: TextField;
	};
}

interface RouteFields {
	Author: Author;
	Categories: Category[];
	Date: TextField;
	Tags: Category[];
	Id: TextField;
	['Last Updated']: TextField;
	Status: TextField;
	BodyRichText: TextField;
	image: ImageField;
	Link: LinkField;
	Summary: TextField;
	Thumbnail: ImageField;
	Title: TextField;
	BrowserTitle: TextField;
	MetaDescription: TextField;
	MetaKeyword: TextField;
	MetaTitle: TextField;
	PageTitle: TextField;
	RobotsTags: TextField;
	TwitterCardType: string | null;
	TwitterDescription: TextField;
	TwitterImage: ImageField;
	TwitterSite: TextField;
	TwitterTitle: TextField;
	OpenGraphAdmins: TextField;
	OpenGraphDescription: TextField;
	OpenGraphImageUrl: ImageField;
	OpenGraphType: TextField;
	OpenGraphSiteName: TextField;
	OpenGraphTitle: TextField;
	OpenGraphAppId: TextField;
	breadcrumbtitle: TextField;
	TitleRawText: TextField;
	'Blog Image': ImageField;
}

interface SitecoreContext {
	route: {
		fields: RouteFields;
	};
}

interface BlogPostDetailsProps {
	sitecoreContext: SitecoreContext;
}

const RawHtml: React.FC<RawHtmlProps> = ({ html }) => {
	return (
		<div className="content-paragraph overhauled" dangerouslySetInnerHTML={{ __html: html }} />
	);
};

const formatDate = (dateString: string): string => {
	const date = new Date(dateString);
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	const month = monthNames[date.getMonth()];
	const day = date.getDate();
	const year = date.getFullYear();

	return `${month} ${day}, ${year}`;
};

interface DateComponentProps {
	dateValue: string;
}

const DateComponent: React.FC<DateComponentProps> = ({ dateValue }) => {
	return <div className="content-title">Last updated {formatDate(dateValue)}</div>;
};

const BlogPostDetails = (props: BlogPostDetailsProps) => {
	const bodyContent = props.sitecoreContext.route.fields.BodyRichText?.value;
	const BlogPostDetails = props.sitecoreContext.route.fields;
	const AuthorPostDetails: Author = props.sitecoreContext.route.fields.Author;
	const categories = props.sitecoreContext.route.fields.Categories;
	const tags = props.sitecoreContext.route.fields.Tags;

	const randomFilters = useMemo(
		() => ({
			category: props.sitecoreContext.route.fields.Categories?.sort(() => 0.5 - Math.random())
				.slice(0, 2)
				.map((category) => category.id.replace(/-/g, '')),
			tag: props.sitecoreContext.route.fields.Tags?.sort(() => 0.5 - Math.random())
				.slice(0, 2)
				.map((tag) => tag.id.replace(/-/g, '')),
		}),
		[AuthorPostDetails?.id]
	);

	const { data } = useQuery(GET_BLOG_POSTS, {
		variables: {
			text: '',
			page: 0,
			pageSize: 6,
			filters: {
				categories: randomFilters.category,
				tags: randomFilters.tag,
				// authors: randomFilters.author,
			},
		},
	});

	return (
		<div className="blog-details overhauled">
			{BlogPostDetails['Blog Image']?.value && (
				<img src={BlogPostDetails['Blog Image'].value as string} alt="Image" className="hero-img" />
			)}
			<div className="jss-inner-container">
				<div className="content-wrapper general-content">
					<RichText tag="h1" className="heading-1" field={BlogPostDetails.TitleRawText} />
					<DateComponent dateValue={BlogPostDetails['Last Updated']?.value} />
					<RawHtml html={bodyContent} />
					<div className="divider" />
					<BlogCategories fields={{ Categories: categories, Tags: tags }} />
					<AuthorComponent fields={AuthorPostDetails?.fields} id={AuthorPostDetails?.id} />
					<div className="divider" />

					<div className="share-bar">
						<h4 className="share-bar-title">Share this story</h4>
						<div className="social-icons">
							<a href="#" className="social-icon">
								<Icons id="facebook-round" />
							</a>
							<a href="#" className="social-icon">
								<Icons id="linkedin-round" />
							</a>
							<a href="#" className="social-icon">
								<Icons id="instagram" />
							</a>
							<a href="#" className="social-icon">
								<Icons id="pinterest" />
							</a>
							<a href="#" className="social-icon">
								<Icons id="twitter-round" />
							</a>
						</div>
					</div>
					<div className="divider" />

					<div className="related-cards-wrapper overhauled">
						<h2>Related</h2>
						<div className="related-cards">
							{data?.blogSearch?.results?.map((article: any) => (
								<a href={article.blogLink} key={article.blogLink} className="article-card">
									<div className="article-image">
										<img className="related-image" src={article.blogImage} alt={article.title} />
									</div>
									<div className="article-content">
										<div className="article-date">{article.publishedDate}</div>
										<h3 className="osf-inner-h3 article-title">{article.title}</h3>
									</div>
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// wrap BlogPostDetails in the HOC (note the double function invocation - important)
export default withSitecoreContext()(BlogPostDetails);
