import React from 'react';
import { Field, Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import EcardImageSelection from 'components/organisms/OLDEcardImageSelection';

type ImageItem = {
	id: string;
	fields: {
		image: {
			value: {
				src: string;
				alt: string;
			};
		};
	};
};

type EcardProps = {
	fields: {
		title: Field<string>;
		description: Field<string>;
		summary: Field<string>;
		requiredMessage: Field<string>;
		imageOptions: Array<ImageItem>;
	};
};

// eslint-disable-next-line
type LayoutProps = StyleguideComponentProps & {};

const EcardData = (props: EcardProps & LayoutProps): JSX.Element => {
	const { fields, rendering } = props;

	return (
		<section className="e-card-container">
			<div className="e-card-outer-container">
				<div className="card-container">
					<div>
						<Text tag="h1" className="heading-1" field={fields?.title || ''} />
						<Text tag="p" field={fields?.description || ''} />
					</div>
					<div className="mx-0 row">
						<div className="col-12 col-sm-12 col-md-6 col-lg-6">
							<Placeholder name="r2-sub-container" rendering={rendering} />
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6">
							<EcardImageSelection {...{ images: fields?.imageOptions }} />
						</div>
						<div className="px-0 col-12 col-sm-12 col-md-6 col-lg-6">
							<Text tag="span" field={fields?.requiredMessage || ''} />
							<RichText className="card-summary" field={fields?.summary} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default EcardData;
